import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const BlockSelf = styled.div`
  position: relative;
  margin-bottom: 105px;
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  .BlockSelf__map {
    width: 100%;
    border-radius: 24px;
    ${mediaInv.laptop`
      min-height: 340px;
  `}
    ${mediaInv.medium`
     & img {
        object-fit: contain !important;
      }
  `}
  ${mediaInv.tablet`
     min-height: initial;
  `}
  }
`;

export const FlexBenefits = styled.div`
  display: flex;
  justify-content: space-between;
  background: rgba(53, 120, 255, 0.3);
  backdrop-filter: blur(27px);
  border-radius: 24px;
  padding: 24px;
  position: absolute;
  bottom: 32px;
  width: 92%;
  left: 50%;
  transform: translateX(-50%);
  ${mediaInv.laptop`
    position: static;
    transform: translateX(0);
    width: 100%;
    padding: 16px 16px 0 16px;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: -10px;
  `}
  ${mediaInv.medium`
    margin-top: -35px;
`}
${mediaInv.tablet`
    margin-top: -10px;
    padding: 8px 8px 0 8px;
`}
`;

export const Benefit = styled.div`
  padding: 16px;
  background: #f0f5ff;
  border-radius: 16px;
  font-family: 'HelveticaNeueCyr';
  color: #3e3e3e;
  width: 21.3%;
  height: auto;
  & .gatsby-image-wrapper {
    width: 40px;
    height: 40px;
  }
  & .Benefit__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 4px 0;
  }
  & .Benefit__text {
    font-size: 16px;
    line-height: 20px;
  }
  ${mediaInv.laptop`
    margin-bottom: 16px;
    width: 45.3%;
  `}
  ${mediaInv.medium`
    margin-bottom: 16px;
    width: 43%;
  `}
  ${mediaInv.tablet`
    width: 100%;
    margin-bottom: 8px;
    height: initial;
`}
`;

export const DescrTop = styled.div`
  background: rgba(53, 120, 255, 0.3);
  backdrop-filter: blur(27px);
  border-radius: 24px;
  padding: 16px 24px;
  font-family: 'Craftwork Grotesk';
  font-size: 28px;
  line-height: 31px;
  color: ${colors.color15};
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 92%;
  ${mediaInv.laptop`
    left: 0;
    transform: translateX(0);
    top: -50px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  `}
  ${mediaInv.tablet`
    margin-bottom: -60px;
    z-index: 10;
    position: relative;
  `}
`;
