import styled from 'styled-components';
import { mediaInv, colors } from '../../ui/common';

export const BreadWrapper = styled.div`
  border-bottom: 1px solid #b9b9b9;
  padding-bottom: 20px;
  margin-top: 130px;
  font-family: 'HelveticaNeueCyr';
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & h1 {
    position: absolute;
    left: -10000px;
  }
  & a.link-svg {
    position: relative;
    display: flex;
    & path {
      transition: 0.3s;
    }
  }
  & a.link-svg:before {
    content: '';
    width: 58px;
    height: 58px;
    background: ${colors.color15};
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    transition: 0.3s;
  }
  & a.link-svg.back-blue rect {
    stroke: none;
  }
  & a.link-svg.back-blue:before {
    background: #224da3;
  }
  & a.link-svg.back-blue svg {
    & path {
      fill: ${colors.color15};
    }

    & svg rect {
      fill: none;
      stroke: none;
    }
  }
  & svg rect {
    transition: 0.3s;
  }
  & svg:hover rect {
    stroke: #224da3;
  }
`;

export const GoBack = styled.a`
  font-size: 12px;
  text-transform: uppercase;
  color: #323232;
  border: 1px solid #b9b9b9;
  border-radius: 30px;
  line-height: 14px;
  padding: 4px 8px;
  margin-right: 5px;
`;

export const Hereweare = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.color15};
  background: #3578ff;
  border-radius: 30px;
  line-height: 14px;
  padding: 4px 8px;
`;

export const BreadRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & span {
    color: ${colors.color26};
    margin-left: 20px;
    font-size: 48px;
    font-family: 'Craftwork Grotesk';
    ${mediaInv.tablet`
    font-size: 36px;
  `}
  }
  & .lang-special.de {
    ${mediaInv.tablet`
    word-break: break-all;
  `}
  }
  & .lang-special.ru {
    ${mediaInv.phone`
    max-width: 215px;
  `}
  }
`;
