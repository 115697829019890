import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ContBig } from '../../ui/common';
import { BreadWrapper, GoBack, Hereweare, BreadRow } from './styled';

function Breadcrumbsnew({ text1, text2 }) {
  const [isActive, setIsActive] = useState(false);

  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  return (
    <ContBig>
      <BreadWrapper>
        <BreadRow>
          <h1>{text2}</h1>
          <GoBack href="/">main</GoBack>
          <Hereweare>{text1}</Hereweare>
        </BreadRow>
        <BreadRow>
          <a
            href="/"
            className={isActive ? 'link-svg back-blue' : 'link-svg'}
            onClick={handleClick}
          >
            <svg
              width="58"
              height="58"
              viewBox="0 0 58 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_5601_18197)">
                <path
                  d="M17.8793 31.0909L21.7493 35.0009C21.8423 35.0946 21.9529 35.169 22.0747 35.2198C22.1966 35.2705 22.3273 35.2967 22.4593 35.2967C22.5913 35.2967 22.722 35.2705 22.8439 35.2198C22.9657 35.169 23.0764 35.0946 23.1693 35.0009C23.263 34.9079 23.3374 34.7973 23.3882 34.6755C23.439 34.5536 23.4651 34.4229 23.4651 34.2909C23.4651 34.1589 23.439 34.0282 23.3882 33.9063C23.3374 33.7844 23.263 33.6738 23.1693 33.5809L19.6093 30.0009H39.9993C40.2645 30.0009 40.5189 29.8955 40.7064 29.708C40.894 29.5204 40.9993 29.2661 40.9993 29.0009C40.9993 28.7357 40.894 28.4813 40.7064 28.2938C40.5189 28.1062 40.2645 28.0009 39.9993 28.0009H19.5493L23.1693 24.3809C23.3466 24.1949 23.4455 23.9478 23.4455 23.6909C23.4455 23.4339 23.3466 23.1869 23.1693 23.0009C23.0764 22.9071 22.9657 22.8328 22.8439 22.782C22.722 22.7312 22.5913 22.7051 22.4593 22.7051C22.3273 22.7051 22.1966 22.7312 22.0747 22.782C21.9529 22.8328 21.8423 22.9071 21.7493 23.0009L17.8793 26.8509C17.3175 27.4134 17.002 28.1759 17.002 28.9709C17.002 29.7659 17.3175 30.5284 17.8793 31.0909Z"
                  fill="#282828"
                />
              </g>
              <rect
                x="1"
                y="1"
                width="56"
                height="56"
                rx="28"
                stroke="#B9B9B9"
                strokeLinejoin="round"
              />
              <defs>
                <clipPath id="clip0_5601_18197">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(17 17)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <span className={currentLocaleClass}>{text2}</span>
        </BreadRow>
      </BreadWrapper>
    </ContBig>
  );
}

export default Breadcrumbsnew;

Breadcrumbsnew.propTypes = {
  text1: PropTypes.node,
  text2: PropTypes.node,
};

Breadcrumbsnew.defaultProps = {
  text1: null,
  text2: null,
};
