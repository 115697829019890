import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ContBig } from '../../ui/common';
import { DiffWrapper, DiffContent } from './styled';
import diff1 from './icons/diff1.svg';
import diff2 from './icons/diff2.svg';
import diff3 from './icons/diff3.svg';

function DiffLanding({ head, titles, texts, links }) {
  const diffs = [diff1, diff2, diff3];

  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );

  const currentLocaleClass2 = useSelector(
    (state) => `DiffItem__title lang-special ${state.locale.picked}`
  );

  return (
    <ContBig>
      <DiffWrapper>
        <h2 className={currentLocaleClass}>{head}</h2>
        <DiffContent>
          {links.map((link, i) => (
            <a
              className="Diff-item"
              href={link}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              <img src={diffs[i]} alt="" />
              <div className={currentLocaleClass2}>{titles[i]}</div>
              <div className="DiffItem__text">{texts[i]}</div>
            </a>
          ))}
        </DiffContent>
      </DiffWrapper>
    </ContBig>
  );
}

export default DiffLanding;

DiffLanding.propTypes = {
  head: PropTypes.node,
  texts: PropTypes.arrayOf(PropTypes.any),
  titles: PropTypes.arrayOf(PropTypes.any),
  links: PropTypes.arrayOf(PropTypes.any),
};

DiffLanding.defaultProps = {
  head: null,
  texts: [],
  titles: [],
  links: [],
};
