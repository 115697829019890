import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import useWindowSize from '../../../utils/useWindowSize';
import { ContBig } from '../../../ui/common';
import { WeAreWhat, UpperWe, WeCare } from './styled';

function WeAre({
  image,
  imageTablet,
  imageMob,
  title,
  subtitle,
  text1,
  text2,
  text3,
  text4,
  text5,
}) {
  const size = useWindowSize();

  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );

  const currentLocaleClass2 = useSelector(
    (state) => `WeCare__title lang-special ${state.locale.picked}`
  );

  return (
    <WeAreWhat>
      <ContBig>
        <UpperWe>
          <div className="UpperWe__block">
            <h2 className={currentLocaleClass}>{title}</h2>
            <p className="UpperWe__subtitle">{subtitle}</p>
            <p className="UpperWe__text">{text1}</p>
            <p className="UpperWe__text">{text2}</p>
            <p className="UpperWe__text">{text3}</p>
          </div>
          <div className="UpperWe__block">
            {size.width > 767 ? (
              <GatsbyImage
                image={size.width > 1199 ? image : imageTablet}
                loading="eager"
              />
            ) : (
              <GatsbyImage image={imageMob} loading="eager" />
            )}
          </div>
        </UpperWe>
        <WeCare>
          <p className={currentLocaleClass2}>{text4}</p>
          <p className="WeCare__text">{text5}</p>
        </WeCare>
      </ContBig>
    </WeAreWhat>
  );
}
export default WeAre;

WeAre.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  imageTablet: PropTypes.objectOf(PropTypes.any),
  imageMob: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  subtitle: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
  text4: PropTypes.node,
  text5: PropTypes.node,
};

WeAre.defaultProps = {
  image: {},
  imageTablet: {},
  imageMob: {},
  title: null,
  subtitle: null,
  text1: null,
  text2: null,
  text3: null,
  text4: null,
  text5: null,
};
