import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import useWindowSize from '../../../utils/useWindowSize';
import { ContBig } from '../../../ui/common';
import { MissionBlock, MissionContent } from './styled';

function Mission({ image, imageTablet, imageMob, title, text }) {
  const size = useWindowSize();
  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );
  return (
    <ContBig>
      <MissionBlock>
        {size.width >= 767 ? (
          <GatsbyImage
            image={size.width >= 1199 ? image : imageTablet}
            loading="eager"
          />
        ) : (
          <GatsbyImage image={imageMob} loading="eager" />
        )}
        <MissionContent>
          <h2 className={currentLocaleClass}>{title}</h2>
          <p>{text}</p>
        </MissionContent>
      </MissionBlock>
    </ContBig>
  );
}
export default Mission;

Mission.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  imageTablet: PropTypes.objectOf(PropTypes.any),
  imageMob: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text: PropTypes.node,
};

Mission.defaultProps = {
  image: {},
  imageTablet: {},
  imageMob: {},
  title: null,
  text: null,
};
