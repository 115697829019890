import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const MissionBlock = styled.div`
  font-family: 'HelveticaNeueCyr';
  margin-bottom: 100px;
  position: relative;
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    ${mediaInv.laptop`
    height: 700px;
  `}
    ${mediaInv.medium`
    height: 600px;
  `}
  }
`;

export const MissionContent = styled.div`
  position: absolute;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  color: ${colors.color15};
  border-radius: 24px;
  backdrop-filter: blur(27px);
  background: rgba(53, 120, 255, 0.3);
  padding: 16px 32px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'HelveticaNeueCyr';
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin: 0 0 16px 0;
  }
  & p {
    margin: 0;
    width: 470px;
    ${mediaInv.tablet`
    width: initial;
 `}
  }
  ${mediaInv.laptop`
    bottom: 20px;
  `}
  ${mediaInv.tablet`
    bottom: 0;
    transform: translateX(0);
    left: 0;
  `}
`;
