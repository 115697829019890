import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContBig } from '../../../ui/common';
import { BlockSelf, FlexBenefits, Benefit, DescrTop } from './styled';

function Map({ image, icons, description, titles, texts }) {
  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );
  const currentLocaleClass2 = useSelector(
    (state) => `Benefit__title lang-special ${state.locale.picked}`
  );
  return (
    <ContBig>
      <BlockSelf>
        <DescrTop className={currentLocaleClass}>{description}</DescrTop>
        <GatsbyImage className="BlockSelf__map" image={image} loading="eager" />
        <FlexBenefits>
          {icons.map((icon, i) => (
            <Benefit>
              <GatsbyImage
                image={icon}
                loading="eager"
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              />
              <div className={currentLocaleClass2}>{titles[i]}</div>
              <div className="Benefit__text">{texts[i]}</div>
            </Benefit>
          ))}
        </FlexBenefits>
      </BlockSelf>
    </ContBig>
  );
}
export default Map;

Map.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  icons: PropTypes.arrayOf(PropTypes.any),
  texts: PropTypes.arrayOf(PropTypes.any),
  titles: PropTypes.arrayOf(PropTypes.any),
  description: PropTypes.node,
};

Map.defaultProps = {
  image: {},
  icons: [],
  description: null,
  texts: [],
  titles: [],
};
