import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import useWindowSize from '../../../utils/useWindowSize';
import { ContBig } from '../../../ui/common';
import { FlexParent, BlockInvLink } from './styled';

function BlockInvert({ image, imageMob, title, text1, text2, text3, invert }) {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const size = useWindowSize();

  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );

  const invertStyle = invert ? 'none' : 'inline-block';

  return (
    <ContBig>
      <FlexParent>
        <div
          className={
            !invert
              ? 'FlexParent__block'
              : 'FlexParent__block FlexParent__block-invert'
          }
        >
          <GatsbyImage
            className="FlexParent__imgdesk"
            image={size.width >= 1200 ? image : imageMob}
            loading="eager"
          />
        </div>
        <div
          className={
            invert ? 'FlexParent__block align-selfblock' : 'FlexParent__block'
          }
        >
          <h2 className={currentLocaleClass}>{title}</h2>
          <p className="FlexParent__text">{text1}</p>
          <p className="FlexParent__text">{text2}</p>
          <BlockInvLink
            style={{
              display: invertStyle,
            }}
            href="https://evapolar.com/en/technology/"
            className={isActive ? 'BlockInvLink__blue' : ''}
            onClick={handleClick}
          >
            {text3}
          </BlockInvLink>
        </div>
      </FlexParent>
    </ContBig>
  );
}
export default BlockInvert;

BlockInvert.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  imageMob: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
  invert: PropTypes.node,
};

BlockInvert.defaultProps = {
  image: {},
  imageMob: {},
  title: null,
  text1: null,
  text2: null,
  text3: null,
  invert: null,
};
