import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import Breadcrumbsnew from '../components/Breadcrumbs';
import WeAre from '../components/AboutUs/Weare';
import BlockInvert from '../components/AboutUs/BlockInvert';
import Mission from '../components/AboutUs/Mission';
import Map from '../components/AboutUs/Map';
import DiffLanding from '../components/DiffLanding';

export const promo = graphql`
  {
    section1: file(relativePath: { eq: "about-us/aboutus1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section1tablet: file(relativePath: { eq: "about-us/aboutus1-tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section1mob: file(relativePath: { eq: "about-us/aboutus1-mob.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section2: file(relativePath: { eq: "about-us/our-story.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section2mob: file(relativePath: { eq: "about-us/our-story-mob.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section3: file(relativePath: { eq: "about-us/mission.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section3tablet: file(relativePath: { eq: "about-us/mission-tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section3mob: file(relativePath: { eq: "about-us/mission-mob.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section4: file(relativePath: { eq: "about-us/ocean.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section5: file(relativePath: { eq: "about-us/map-desk.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    icon1: file(relativePath: { eq: "about-us/icon1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    icon2: file(relativePath: { eq: "about-us/icon2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    icon3: file(relativePath: { eq: "about-us/icon3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    icon4: file(relativePath: { eq: "about-us/icon4.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
  }
`;

export default function aboutUs() {
  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <>
          <NavBar />

          <Breadcrumbsnew
            text1={<FormattedMessage id="about_us.breadcrumb.text1" />}
            text2={<FormattedMessage id="about_us.breadcrumb.text2" />}
          />

          <WeAre
            image={data.section1.childImageSharp.gatsbyImageData}
            imageTablet={data.section1tablet.childImageSharp.gatsbyImageData}
            imageMob={data.section1mob.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="about_us.section1.title" />}
            subtitle={<FormattedMessage id="about_us.section1.subtitle" />}
            text1={<FormattedMessage id="about_us.section1.text1" />}
            text2={<FormattedMessage id="about_us.section1.text2" />}
            text3={<FormattedMessage id="about_us.section1.text3" />}
            text4={<FormattedMessage id="about_us.section1.text4" />}
            text5={<FormattedMessage id="about_us.section1.text5" />}
          />

          <BlockInvert
            image={data.section2.childImageSharp.gatsbyImageData}
            imageMob={data.section2mob.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="about_us.section2.title" />}
            text1={<FormattedMessage id="about_us.section2.text1" />}
            text2={<FormattedMessage id="about_us.section2.text2" />}
            text3={<FormattedMessage id="about_us.section2.text3" />}
            invert={false}
          />

          <Mission
            image={data.section3.childImageSharp.gatsbyImageData}
            imageTablet={data.section3tablet.childImageSharp.gatsbyImageData}
            imageMob={data.section3mob.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="about_us.section3.title" />}
            text={<FormattedMessage id="about_us.section3.text" />}
          />

          <BlockInvert
            image={data.section4.childImageSharp.gatsbyImageData}
            imageMob={data.section4.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="about_us.section4.title" />}
            text1={<FormattedMessage id="about_us.section4.text1" />}
            text2={<FormattedMessage id="about_us.section4.text2" />}
            text3={<FormattedMessage id="about_us.section4.text3" />}
            invert
          />

          <Map
            image={data.section5.childImageSharp.gatsbyImageData}
            icons={[
              data.icon1.childImageSharp.gatsbyImageData,
              data.icon2.childImageSharp.gatsbyImageData,
              data.icon3.childImageSharp.gatsbyImageData,
              data.icon4.childImageSharp.gatsbyImageData,
            ]}
            description={<FormattedMessage id="about_us.section5.text1" />}
            titles={[
              <FormattedMessage id="about_us.section5.text2" />,
              <FormattedMessage id="about_us.section5.text3" />,
              <FormattedMessage id="about_us.section5.text4" />,
              <FormattedMessage id="about_us.section5.text5" />,
            ]}
            texts={[
              <FormattedMessage id="about_us.section5.text6" />,
              <FormattedMessage id="about_us.section5.text7" />,
              <FormattedMessage id="about_us.section5.text8" />,
              <FormattedMessage id="about_us.section5.text9" />,
            ]}
          />

          <DiffLanding
            head={<FormattedMessage id="about_us.difference.head" />}
            titles={[
              <FormattedMessage id="about_us.difference.item1.title" />,
              <FormattedMessage id="about_us.difference.item2.title" />,
              <FormattedMessage id="about_us.difference.item3.title" />,
            ]}
            texts={[
              <FormattedMessage id="about_us.difference.item1.text" />,
              <FormattedMessage id="about_us.difference.item2.text" />,
              <FormattedMessage id="about_us.difference.item3.text" />,
            ]}
            links={[
              'https://evapolar.com/en/shop/',
              'https://evapolar.com/blog',
              'https://support.evapolar.com/hc/en-us/requests/new',
            ]}
          />

          <Footer />
        </>
      )}
    />
  );
}
