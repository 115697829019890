import styled from 'styled-components';
import { mediaInv } from '../../ui/common';

export const DiffWrapper = styled.div`
  margin-bottom: 100px;
  color: #323232;
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 24px;
  }
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina' !important;
    font-weight: 300;
  }
`;

export const DiffContent = styled.div`
  display: flex;
  justify-content: space-between;
  & .Diff-item {
    min-height: 227px;
    background: #f0f5ff;
    border-radius: 24px;
    padding: 16px;
    width: 32.1%;
    box-sizing: border-box;
    ${mediaInv.laptop`
     width: 48.5%;
     margin-bottom: 24px;
  `}
    ${mediaInv.medium`
    width: 48%;
  `}
    ${mediaInv.tablet`
    margin-bottom: 16px;
    width: 100%;
    `}
    & .gatsby-image-wrapper {
      width: 64px;
      height: 64px;
    }
    & .DiffItem__title {
      font-family: 'Craftwork Grotesk';
      font-size: 28px;
      line-height: 31px;
      margin: 22px 0 14px 0;
    }
    & .DiffItem__text {
      font-family: 'HelveticaNeueCyr';
      font-size: 16px;
      line-height: 20px;
      opacity: 0.8;
    }
  }
  ${mediaInv.laptop`
    flex-wrap: wrap;
    & .Diff-item:last-child  {
        width: 100%;
        margin-bottom: 0;
     }
  `}
`;
