import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const FlexParent = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'HelveticaNeueCyr';
  color: ${colors.color26};
  margin-bottom: 100px;
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & .BlockInvLink-hide {
    display: none;
  }
  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    ${mediaInv.laptop`
    height: 470px;
    & img {
      object-position: top;
      border-radius: 24px;
    }
  `}
    ${mediaInv.tablet`
     height: 400px;
     & img {
      object-position: center;
      border-radius: 24px;
    }
  `}
  }
  & .FlexParent__block {
    width: 48%;
    ${mediaInv.laptop`
    width: 46%;
  `}
    ${mediaInv.tablet`
    width: 100%;
    margin-bottom: 24px;
  `}
  }
  & .align-selfblock {
    align-self: center;
  }
  & .FlexParent__block-invert {
    order: 2;
    ${mediaInv.laptop`
    & .FlexParent__imgdesk {
      height: 350px;
     }
  `}
    ${mediaInv.tablet`
     order: 1;
     & + div {
      order: 2;
     }
  `}
  }
  & .FlexParent__text {
    margin: 16px 0;
    font-size: 16px;
    line-height: 20px;
  }
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-size: 36px;
    line-height: 39px;
    font-weight: 400;
    margin: 20px 0 0 0;
    ${mediaInv.laptop`
    margin: 0;
  `}
  }
  & a.BlockInvLink__blue {
    background: #2b61ce;
    color: ${colors.color15};
  }
  ${mediaInv.laptop`
     align-items: center;
  `}
  ${mediaInv.tablet`
     flex-wrap: wrap;
     margin-bottom: 70px;
  `}
`;

export const BlockInvLink = styled.a`
  border: 1px solid #2b61ce;
  border-radius: 500px;
  padding: 16px;
  transition: 0.3s;
  font-size: 18px;
  line-height: 24px;
  margin: 8px 0 0 0;
  display: inline-block;
  & :hover {
    color: #848484;
  }
`;
