import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const WeAreWhat = styled.div`
  padding-top: 40px;
  margin-bottom: 60px;
  font-family: 'HelveticaNeueCyr';
  color: ${colors.color26};
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
`;

export const WeCare = styled.div`
  background: #f0f5ff;
  border-radius: 24px;
  padding: 24px 0;
  ${mediaInv.laptop`
  padding: 30px 24px;
  `}
  ${mediaInv.tablet`
  padding: 30px 16px;
  `}
  & p {
    max-width: 700px;
  }
  & p {
    max-width: 700px;
    font-size: 20px;
    line-height: 24px;
    margin: 0 auto 12px auto;
    text-align: center;
  }
  & .WeCare__title {
    font-family: 'Craftwork Grotesk';
    font-size: 28px;
    line-height: 31px;
  }
`;

export const UpperWe = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  ${mediaInv.tablet`
     flex-wrap: wrap;
     margin-bottom: 70px;
  `}
  & .UpperWe__block {
    width: 49%;
    ${mediaInv.laptop`
    width: 46.5%;
  `}
    ${mediaInv.tablet`
      width: 100%;
  `}
  }
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-size: 36px;
    line-height: 39px;
    font-weight: 400;
    margin: 30px 0 15px 0;
    ${mediaInv.laptop`
     margin-top: 0;
  `}
    ${mediaInv.tablet`
  font-size: 42px;
  line-height: 45px;
  `}
  }
  & .UpperWe__subtitle {
    font-weight: 550;
    line-height: 20px;
    margin: 0 0 15px 0;
    ${mediaInv.laptop`
     margin-bottom: 24px;
  `}
  }
  & .UpperWe__text {
    line-height: 20px;
    margin: 8px 0 0 0;
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: 350px;
    border-radius: 24px;
    ${mediaInv.laptop`
    & img {
      border-radius: 24px;
    }
  `}
    ${mediaInv.tablet`
    margin-top: 24px;
    min-height: 325px;
    & img {
      border-radius: 24px;
    }
  `}
  }
  ${mediaInv.laptop`
  & .UpperWe__text:last-child {
     display: none;
  }
  `}
`;
